import { xenn } from './index';

export default {
  login(credentials) {
    return xenn.post('/auth/login', credentials);
  },
  tokenLogin(credential) {
    return xenn.post('/auth/via-token', credential);
  },
  resetPassword(params) {
    return xenn.post('/auth/reset-password', params);
  },
  changePassword(params) {
    return xenn.post('/auth/update-password', params);
  },
};
