<template>
  <div>
    <!-- Header -->
    <div class="header bg-primary py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-3">
          <b-row class="justify-content-center">
          </b-row>
        </div>
      </b-container>
    </div>
    <!-- Page content -->
    <b-container class="mt--200 pb-3">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-header class="bg-transparent pb-4">
              <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
              <div class="btn-wrapper text-center">
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <GoogleLogin />
              </div>
            </b-card-header>
            <b-card-body class="px-lg-2 py-lg-2">
              <div class="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="Email"
                              :rules="{required: true, email: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              v-model="loginModel.email">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="loginModel.password">
                  </base-input>

                  <b-form-checkbox v-model="loginModel.rememberMe">Remember me</b-form-checkbox>
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">Sign in</base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <b-col cols="6">
              <router-link to="/dashboard" class="text-light"><small>Forgot password?</small></router-link>
            </b-col>
            <b-col cols="6" class="text-right">
              <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import loginService from '@/services/login';
import swal from 'sweetalert2';
import GoogleLogin from '@/components/GoogleLogin.vue';

export default {
  components: { GoogleLogin },
  created() {
    this.logout();
    if (this.$route.query.token) {
      this.save(this.$route.query.token);
    }
  },
  data() {
    return {
      loginModel: {},
      error: undefined,
    };
  },
  methods: {
    ...mapActions(['updateAuthToken', 'logout', 'initialize', 'afterLogin']),
    onSubmit() {
      loginService.login(this.loginModel)
        .then((response) => {
          if (response.hasError) {
            swal.fire({
              title: `Warning`,
              text: response.message.error,
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning',
              icon: 'warning'
            });
          } else {
            this.saveToken(response.auth_token);
          }
        });
    },
    saveToken(token) {
      this.updateAuthToken(token)
        .then(() => {
          this.initialize()
            .then(() => {
              this.afterLogin();
              this.$router.push(this.$route.query.redirect || '/dashboard');
            });
        });
    },
  },
};
</script>
