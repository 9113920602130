<template>
  <a href="#" class="btn btn-neutral btn-icon">
    <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
    <span class="btn-inner--text">Github</span>
  </a>
</template>

<script>
export default {
  name: 'GoogleLogin',
  mounted() {
    window.onSignIn = this.onSignIn;
  },
  methods: {
    onSignIn(googleUser) {
      const profile = googleUser.getBasicProfile();
      console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
      console.log('Name: ' + profile.getName());
      console.log('Image URL: ' + profile.getImageUrl());
      console.log('Email: ' + profile.getEmail());

      // The ID token you need to pass to your backend:
      const id_token = googleUser.getAuthResponse().id_token;
      console.log('ID Token: ' + id_token);

      // Send the ID token to your backend via a POST request
      this.$http.post('/tokensignin', { id_token })
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>
